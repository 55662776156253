.input_size_default {
  width: 480px;
}

@media screen and (max-width: 375px) {
  .input__placeholder {
    display: block;
    position: absolute;
    cursor: text;
    top: 12px;
    text-align: left;
    color: var(--text-inactive-color);
    transition: 0.3s;
  }
}

@media screen and (max-width: 375px) {
  .input__placeholder-focused{
    top: 8px;
  }
}

@media screen and (max-width: 375px) {
  .input__placeholder-filled{
    top: 8px;
  }
}