.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1C1C21;
  border-radius: 40px;
  width: 720px;
  z-index: 7;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 40px 60px;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 640px;
  height: 64px;
  margin: 0;
}

.title {
  font-family: 'Jet Brains Mono';
  font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 1.14;
  margin: 0;
}

.button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  background-color: transparent;
  border: none;
  transition: opacity 1s linear 0.5s;
  cursor: pointer;
  padding: 0;
}

.button:hover {
  opacity: .6;
}

.key {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  background-color: transparent;
  border: none;
  transition: opacity 1s linear 0.5s;
  cursor: pointer;
  padding: 0;
}

.key:hover {
  opacity: .6;
}

@media screen and (max-width: 750px) {
  .modal {
    width: 550px;
  }
}

@media screen and (max-width: 750px) {
  .header {
    width: 470px;
  }
}

@media screen and (max-width: 580px) {
  .modal {
    width: 430px;
  }
}

@media screen and (max-width: 580px) {
  .header {
    width: 350px;
  }
}

@media screen and (max-width: 480px) {
  .modal {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

@media screen and (max-width: 480px) {
  .container {
    margin: 20px;
  }
}

@media screen and (max-width: 480px) {
  .header {
    width: 100%;
    height: 50px;
  }
}

@media screen and (max-width: 330px) {
  .container {
    margin: 0 8px;
  }
}

@media screen and (max-width: 330px) {
  .header {
    height: 64px;
  }
}