.container {
  margin: 180px auto 0px;
  max-width: 480px;
  text-align: center;
}

.wrapper {
  max-width: 480px;
}

.content {
  display: grid;
  grid-template-rows: repeat(4, minmax(min-content, max-content));
  row-gap: 24px;
  margin: 24px 0px 80px;
}

.span {
  color: #EE3465;
}

.box {
  display: flex;
  justify-content: center;
}

.link {
  text-decoration: none;
  color: #4C4CFF;
  transition: opacity 1s linear 0.5s;
}

.link:hover {
  opacity: .6;
}

@media screen and (max-width: 375px) {
  .container {
    margin: 16px auto 0px;
    max-width: 304px;
  }
}

@media screen and (max-width: 375px) {
  .wrapper {
    max-width: 250px;
    margin: auto;
  }
}

@media screen and (max-width: 375px) {
  .content {
    row-gap: 20px;
    margin: 24px 0px 10px;
  }
}

@media screen and (max-width: 375px) {
  .box {
    display: block;
  }
}