.burger {
  max-width: 600px;
  height: calc(100vh - 88px);
  position: relative;
}

.list {
  display: grid;
  row-gap: 16px;
  list-style-type: none;
  margin: 0;
  padding: 0 4px 0 0;
  height: 368px;
  overflow: scroll;
  overflow-x: hidden;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
}

.enum {
  display: grid;
  row-gap: 16px;
  list-style-type: none;
  margin: 0;
  padding: 0 4px 0 0;
  height: 368px;
}

.list::-webkit-scrollbar {
	width: 12px;
}

.list::-webkit-scrollbar-track {
	background: #2F2F37;
}

.list::-webkit-scrollbar-thumb {
  height: 290px;
	background: #8585AD;
	border: 3px solid #2F2F37;
	border-radius: 6px;
}

.wrapper {
  margin-right: 5px;
}

.heading {
  position: absolute;
  width: 535px;
  margin: 0;
  top: 30px;
  left: 45px;
  display: none;
  justify-content: space-between;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  min-height: calc(100% - 155px);
  margin: 100px 0 55px 16px;
}

.content {
  display: grid;
  justify-items: end;
  row-gap: 16px;
}

.shell {
  margin-right: 16px;
}

.top {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  width: 488px;
  height: 72px;
  background-color: #2f2f37;
  border-radius: 88px 88px 40px 40px;
  font-family: 'Jet Brains Mono';
  font-size: 16px;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  width: 488px;
  height: 72px;
  background-color: #2f2f37;
  border-radius: 40px 40px 88px 88px;
  font-family: 'Jet Brains Mono';
  font-size: 16px;
}

.middle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 488px;
  background-color: #2f2f37;
  border-radius: 30px;
  font-family: 'Jet Brains Mono';
  font-size: 16px;
}

@media screen and (max-width: 1300px) {
  .heading {
    display: flex;
  }
}

@media screen and (max-width: 1030px) {
  .list {
    row-gap: 8px;
    height: 340px;
  }
}

@media screen and (max-width: 1030px) {
  .heading {
    top: 8px;
  }
}

@media screen and (max-width: 1030px) {
  .container {
    min-height: calc(100% - 93px);
    margin-top: 70px;
  }
}

@media screen and (max-width: 1030px) {
  .content {
    row-gap: 8px;
  }
}

@media screen and (max-width: 770px) {
  .list {
    row-gap: 16px;
    height: 368px;
  }
}

@media screen and (max-width: 770px) {
  .heading {
    top: 30px;
  }
}

@media screen and (max-width: 770px) {
  .container {
    min-height: calc(100% - 155px);
    margin-top: 100px;
  }
}

@media screen and (max-width: 770px) {
  .content {
    row-gap: 16px;
  }
}

@media screen and (max-width: 600px) {
  .list {
    grid-template-rows: repeat(17, minmax(min-content, max-content));
    row-gap: 0;
    padding: 0;
    height: calc(100% - 52px);
  }
}

@media screen and (max-width: 600px) {
  .list::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    margin-right: 0;
  }
}