.menu {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1C1C21;
  z-index: 6;
}

.content {
  margin: 0 10px;
  list-style-type: none;
  padding: 0;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
}

.features {
  padding: 0;
  background-color: transparent;
  border: none;
  transition: opacity 1s linear 0.5s;
  cursor: pointer;
}

.features:hover {
  opacity: .6;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #8585AD;
  transition: opacity 1s linear 0.5s;
}

.active {
  color: #F2F2F3;
}

.wrapper {
  margin-left: 10px;
}