.container {
  display: flex;
  max-width: 1240px;
  margin: 120px auto;
}

.box {
  margin: 120px auto 0px;
  max-width: 480px;
  text-align: center;
}

.content {
  display: grid;
  grid-template-rows: repeat(5, 64px);
  max-width: 320px;
  margin-right: 60px;
}

.link {
  align-self: center;
  font-family: 'Jet Brains Mono';
	font-weight: bold;
	font-size: 24px;
	line-height: 1.25;
  color: #8585AD;
  text-decoration: none;
  transition: opacity 1s linear 0.5s;
}

.active {
  color: #F2F2F3;
}

.link:active {
  align-self: center;
  font-family: 'Jet Brains Mono';
	font-weight: bold;
	font-size: 24px;
	line-height: 1.25;
  color: #F2F2F3;
  text-decoration: none;
  transition: opacity 1s linear 0.5s;
}

.wrapper {
  grid-row: 5;
  align-self: end;
}

.frame {
  display: flex;
  flex-direction: column;
}

.form {
  display: grid;
  grid-template-rows: repeat(4, minmax(min-content, max-content));
  row-gap: 24px;
  max-width: 480px;
}

.span {
  color: #EE3465;
  text-align: center;
}

.button {
  margin: auto;
}

@media screen and (max-width: 1100px) {
  .container {
    max-width: 984px;
  }
}

@media screen and (max-width: 500px) {
  .frame {
    align-items: center;
  }
}

@media screen and (max-width: 350px) {
  .box {
    margin: 16px auto 0px;
    max-width: 304px;
  }
}

@media screen and (max-width: 350px) {
  .form {
    row-gap: 20px;
  }
}