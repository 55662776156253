.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  margin-right: 5px;
}

@media screen and (max-width: 600px) {
  .wrapper {
    margin-right: 0;
  }
}