.dropdown {
  margin-bottom: 8px;
  margin-left: 35px;
  list-style-type: none;
  padding: 0;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  display: flex;
  text-decoration: none;
  color: #8585AD;
  transition: opacity 1s linear 0.5s;
}

.active {
  color: #F2F2F3;
}

.wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
}