.orders {
  margin-top: -80px;
  width: 860px;
}

.cards {
  display: grid;
  grid-template-rows: repeat(3, minmax(min-content, max-content));
  grid-auto-rows: minmax(min-content, max-content);
  row-gap: 16px;
  height: calc(100vh - 350px);
  overflow: scroll;
  overflow-x: hidden;
}

.cards::-webkit-scrollbar {
	width: 12px;
}

.cards::-webkit-scrollbar-track {
	background: #2F2F37;
}

.cards::-webkit-scrollbar-thumb {
  height: 640px;
	background: #8585AD;
	border: 3px solid #2F2F37;
  border-radius: 6px;
}

@media screen and (max-width: 1500px) {
  .cards {
    height: calc(100vh - 320px);
  }
}

@media screen and (max-width: 1100px) {
  .orders {
    width: 600px;
  }
}

@media screen and (max-width: 1100px) {
  .cards {
    height: calc(100vh - 250px);
  }
}

@media screen and (max-width: 800px) {
  .orders {
    margin-top: 0;
  }
}

@media screen and (max-width: 800px) {
  .cards {
    height: calc(100vh - 220px);
  }
}

@media screen and (max-width: 500px) {
  .orders {
    width: 425px;
  }
}

@media screen and (max-width: 500px) {
  .cards {
    height: calc(100vh - 200px);
  }
}

@media screen and (max-width: 500px) {
  .cards::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .orders {
    width: 375px;
  }
}

@media screen and (max-width: 350px) {
  .cards {
    height: calc(100vh - 140px);
  }
}

@media screen and (max-width: 350px) {
  .orders {
    width: 320px;
  }
}