.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 232px;
}

.title {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.19;
}

.title span {
  display: block;
  font-size: 140px;
  line-height: 1.21;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 17px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.21;
  color: #4285F4;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.button:hover {
  transform: scale(1.05);
}

.button:active {
  transform: scale(1);
}

@media screen and (max-width: 320px) {
  .container {
    margin-top: 116px;
  }
}

@media screen and (max-width: 320px) {
  .title {
    font-size: 12px;
    line-height: 1.25;
  }
}

@media screen and (max-width: 320px) {
  .title span {
    font-size: 80px;
    line-height: 1.21;
  }
}

@media screen and (max-width: 320px) {
  .button {
    font-size: 12px;
    line-height: 1.25;
  }
}