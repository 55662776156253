.container {
  display: flex;
  justify-content: space-between;
  max-width: 1240px;
  margin: 120px auto;
}

@media screen and (max-width: 1100px) {
  .container {
    max-width: 984px;
  }
}

@media screen and (max-width: 900px) {
  .container {
    flex-direction: column;
    align-items: center;
    margin: 30px auto;
  }
}

@media screen and (max-width: 350px) {
  .container {
    margin: -10px auto;
  }
}