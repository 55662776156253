.image {
  width: 480px;
  height: 240px;
}

.wrapper {
  text-align: center;
}

.list {
  display: flex;
  justify-content: space-between;
  width: 520px;
  height: 56px;
  list-style-type: none;
  padding: 0;
}

.container {
  display: flex;
  margin-left: 20px;
}

.box {
  display: flex;
  margin-right: 20px;
}

.item {
  width: 120px;
  height: 56px;
}

@media screen and (max-width: 580px) {
  .list {
    flex-direction: column;
    align-items: center;
    width: 400px;
  }
}

@media screen and (max-width: 580px) {
  .container {
    margin-left: 0;
  }
}

@media screen and (max-width: 480px) {
  .image {
    width: 420px;
    height: 210px;
  }
}

@media screen and (max-width: 480px) {
  .list {
    margin-top: 100px;
  }
}

@media screen and (max-width: 430px) {
  .image {
    width: 370px;
    height: 185px;
  }
}

@media screen and (max-width: 430px) {
  .wrapper {
    max-width: 340px;
  }
}

@media screen and (max-width: 430px) {
  .list {
    width: 350px;
  }
}

@media screen and (max-width: 330px) {
  .image {
    margin-top: 20px;
    width: 250px;
    height: 125px;
  }
}

@media screen and (max-width: 330px) {
  .list {
    width: 300px;
    margin-top: 0;
  }
}

@media screen and (max-width: 330px) {
  .container {
    margin-left: 20px;
  }
}

@media screen and (max-width: 330px) {
  .box {
    display: flex;
    margin-left: 20px;
  }
}