.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
}

@media screen and (max-width: 580px) {
  .container {
    margin-top: 60px;
  }  
}

@media screen and (max-width: 330px) {
  .container {
    margin-top: 10px;
  }  
}