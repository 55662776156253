.link {
  text-decoration: none;
  color: #F2F2F3;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  list-style-type: none;
  padding: 0;
}

.image {
  display: block;
  width: 240px;
  height: 120px;
  object-fit: cover;
}

.counter {
  position: absolute;
  top: 0;
  right: 0;
}

.price {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.name {
  max-width: 260px;
  min-height: 48px;
  text-align: center;
}

.button {
  display: none;
  margin-top: 8px;
  transition: opacity 1s linear 0.5s;
  cursor: pointer;
}

.button:hover {
  opacity: .6;
}

.line {
  margin: 0;
  height: 1px;
  background-color: #2F2F37;
  border: none;
}

@media screen and (max-width: 1300px) {
  .button {
    display: block;
  }
}

@media screen and (max-width: 540px) {
  .image {
    width: 144px;
    height: 72px;
  }
}

@media screen and (max-width: 540px) {
  .name {
    width: 144px;
    height: 60px;
  }
}

@media screen and (max-width: 340px) {
  .counter {
    right: 16px;
  }
}