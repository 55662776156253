.orders {
  width: 596px;
}

.cards {
  display: grid;
  grid-template-rows: repeat(3, minmax(min-content, max-content));
  grid-auto-rows: minmax(min-content, max-content);
  row-gap: 16px;
  height: calc(100vh - 190px);
  overflow: scroll;
  overflow-x: hidden;
}

.cards::-webkit-scrollbar {
	width: 12px;
}

.cards::-webkit-scrollbar-track {
	background: #2F2F37;
}

.cards::-webkit-scrollbar-thumb {
  height: 640px;
	background: #8585AD;
	border: 3px solid #2F2F37;
  border-radius: 6px;
}

@media screen and (max-width: 1500px) {
  .cards {
    height: calc(100vh - 210px);
  }
}

@media screen and (max-width: 1300px) {
  .orders {
    margin: auto;
  }
}

@media screen and (max-width: 1300px) {
  .cards {
    height: calc(100vh - 260px);
  }
}

@media screen and (max-width: 500px) {
  .cards::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .cards {
    height: calc(100vh - 230px);
  }
}

@media screen and (max-width: 375px) {
  .cards {
    height: calc(100vh - 230px);
  }
}