.content {
  display: grid;
  grid-template-rows: repeat(5, 64px);
  max-width: 320px;
}

.link {
  align-self: center;
  font-family: 'Jet Brains Mono';
	font-weight: bold;
	font-size: 24px;
	line-height: 1.25;
  color: #8585AD;
  text-decoration: none;
  transition: opacity 1s linear 0.5s;
}

.active {
  color: #F2F2F3;
}

.link:active {
  align-self: center;
  font-family: 'Jet Brains Mono';
	font-weight: bold;
	font-size: 24px;
	line-height: 1.25;
  color: #F2F2F3;
  text-decoration: none;
  transition: opacity 1s linear 0.5s;
}

.wrapper {
  grid-row: 5;
  align-self: end;
}